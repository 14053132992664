import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

const app = initializeApp({
  projectId: "raiseio",
  apiKey: "AIzaSyAUF2cIc0d_X1ttMaPBnRnJj9hEy0EknMo",
  authDomain: "raiseio.firebaseapp.com",
});
const functions = getFunctions(app, "europe-west2");
const form = document.getElementById("subscribe-form");

form.addEventListener("submit", (event) => {
  event.preventDefault();

  if (!event.target.checkValidity() || form.classList.contains("submitting"))
    return;

  grecaptcha.ready(function() {
    grecaptcha.execute('6Lfw3P8UAAAAAFhKp_M_SKyd-yKEWEJ4fJZPC3KT', {action: 'submit'}).then(function(token) {
      onSubmit(token);
    });
  });
});

const onSubmit = async (token) => {
  const registerInterest = httpsCallable(functions, "registerInterestOnCall");
  form.classList.add("submitting");
  const formData = new FormData(form);
  const { name, charity, email } = Object.fromEntries(formData);

  const call = await registerInterest({
    token,
    name,
    charity,
    email,
  });

  form.innerHTML = `
    <div><strong>Thank you for your interest.</strong></div>
    <div><strong>We'll be in touch.</strong></div>
  `;
  form.classList.remove("submitting");
};
